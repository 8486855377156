import React, { Component, CSSProperties } from 'react'
import Row from 'react-bootstrap/Row'
import EventCell from './EventCell'
import EventRowCell from './EventRowCell'
import CardDeck from 'react-bootstrap/CardDeck'
import CardColumns from 'react-bootstrap/CardColumns'
import CardGroup from 'react-bootstrap/CardGroup'
import { Default, Mobile } from './ViewTypes'
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from 'react-icons/fa'
import { print } from '../managers/Logger'
import PlaceCardCell from './PlaceCardCell'
import ReactGA from 'react-ga'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import { canoc } from '../managers/Utils'

export type PagingStyle = "overlay" | "top-right" | "bottom" | "none"

export type ItemRowProps = {
	items: any[],
	allowHorizontalOverflow?: boolean,
	getItemRender?: (item: any) => any,
	pageStyle?: PagingStyle,
	// Stop using this. Instead use the getItemRenderer to have complete control over what is rendered.
	kind?: "event" | "place"
	rowItemCount?: number,
	title?: string,
	isMobile?: boolean,
	getTitle?: () => any,
	subtitle?: string,
	onEventClick?: (eventId: string) => void,
	onVideoClick?: (url: string, eventId: string) => void,
	hideControls?: boolean,
	aff?: boolean
}

type ItemRowState = {
	sections: any[],
	currentIndex: number,
	mouseOnDiv: boolean,
}


/**
 * Reprents a row of items usually of the same time. 
 */
export default class ItemRow extends React.Component<ItemRowProps, ItemRowState> {

	static defaultProps = {
		rowItemCount: 4,
		kind: 'event',
		subtitle: null,
		title: null,
		items: [],
		isMobile: null
	}


	constructor(props) {
		super(props)
		this.state = {
			currentIndex: 0,
			mouseOnDiv: false,
			sections: this.chunk(props.items, props.rowItemCount)
		}


	}

	componentDidMount() {
		// if (this.props.allowHorizontalOverflow) { 
		// 	return;
		// }
		const chunks = this.chunk(this.props.items, this.props.rowItemCount)
		print(chunks)
		this.setState({ sections: chunks })
	}

	chunk(arr, chunkSize) {
		if (arr === null || arr === undefined) {
			return []
		}

		var R = [];
		for (var i = 0, len = arr.length; i < len; i += chunkSize)
			R.push(arr.slice(i, i + chunkSize));
		return R;
	}

	getViewMore() {
		if (this.props.items.length > 3) {
			return (<p> View more </p>)
		} else {
			return null
		}
	}

	getInitialChunk() {
		// if (this.props.allowHorizontalOverflow) {
		// 	return this.props.items
		// }

		const values = this.state.sections[this.state.currentIndex]
		if (values === undefined) {
			return []
		} else {
			return values
		}
	}

	getLeftButton() {
		if (this.state.currentIndex == 0) {
			return <Button variant="light" style={this.props.allowHorizontalOverflow ? {
				position: 'absolute',
				left: '-20px',
				top:'115px',
				zIndex:2
			}: {}} disabled><FaArrowAltCircleLeft style={{ color: 'gray' }} /></Button>
		}

		return <Button variant="light" style={this.props.allowHorizontalOverflow ? {
			position: 'absolute',
			left: '-20px',
			top:'115px',
			zIndex:2
		}: {}} onClick={() => this.setState({ currentIndex: this.state.currentIndex - 1 })}><FaArrowAltCircleLeft /></Button>
	}

	getRightButton() {
		if (this.state.currentIndex + 1 >= this.state.sections.length) {
			return <Button variant="light" style={this.props.allowHorizontalOverflow ? {
				position: 'absolute',
				right: '0px',
				top:'115px',
				zIndex:2
			}: {}} disabled><FaArrowAltCircleRight style={{ color: 'gray' }} /></Button>
		}

		return <Button variant="light" style={this.props.allowHorizontalOverflow ? {
			position: 'absolute',
			right: '0px',
			top:'115px',
			zIndex:2
		}: {}} onClick={() => this.setState({ currentIndex: this.state.currentIndex + 1 })}><FaArrowAltCircleRight /></Button>
	}

	getLonelyType(mobile) {
		if (this.props.items.length === 1 && !mobile) {
			return "lonely"
		} else {
			return undefined
		}
	}

	getEventLink(item) { 
		if (item.link) { 
			return item.link
		} else { 
			return "/event/" + canoc(item.title, item.id) + "?aff=" + this.props.aff
		}
	}

	handlePlaceClick(item) {
		ReactGA.event({
			category: 'Section',
			action: 'place_click'
		})
		window.location.href = item.link
	}

	getRenderer(item, mobile = false, width = null, horizontal = false) {
		if (this.props.getItemRender) {
			return this.props.getItemRender(item);
		}

		if (this.props.kind === "event" || this.props.kind === undefined) {
			return <a href={`${this.getEventLink(item)}`} style={{color: 'black'}}><EventCell
				style={{ width: width, marginRight: '10px', marginBottom: '10px' }}
				key={item.id}
				type={this.getLonelyType(mobile)}
				event={item}
				horizontal={horizontal}
				mobile={mobile}
				onClick={(eventId) => this.props.onEventClick(eventId)}
				onVideoClick={(url, event_id) => this.props.onVideoClick(url, event_id)}
			/></a>
		}

		if (this.props.kind === "place") {
			return <PlaceCardCell
				key={item.id}
				place={item}
				type={this.getLonelyType(mobile)}
				horizontal={horizontal}
				mobile={mobile}
				width={width}
				onClick={() => this.handlePlaceClick(item)}
			/>
		} else {
			return null
		}
	}

	getListStyle() { 
		const defaultStyle: CSSProperties = { display: 'flex'}
		if (this.props.allowHorizontalOverflow) { 
			 defaultStyle.overflowX = 'auto'
			 defaultStyle.flexWrap = 'nowrap';
		}

		return defaultStyle
	}


	componentDidUpdate(prevProps) { 
		if (prevProps.items !== this.props.items) { 
			const chunks = this.chunk(this.props.items, this.props.rowItemCount);
			this.setState({ sections: chunks });
		}
	}

	render() {
		if (this.props.items === undefined || this.props.items === null || this.props.items.length == 0) {
			return null
		}

		const mobileView = <>{this.props.getTitle && this.props.getTitle()}
			{!this.props.getTitle && <h3>{this.props.title}</h3>}
			<p style={{ color: 'gray' }}>{this.props.subtitle}</p>
			<div style={{ display: 'flex', flexWrap: 'nowrap', overflowX: 'auto', WebkitOverflowScrolling: 'touch' }}>
				{this.props.items.map((item, index) => this.getRenderer(item, true, "300px", true))}
			</div>
		</>

		const defaultView = <>
				<Row>
				<Col style={{paddingLeft: 0}}>
					{this.props.getTitle && this.props.getTitle()}
					{!this.props.getTitle && this.props.title && <h3>{this.props.title}</h3>}
					{this.props.subtitle && <p style={{ color: 'gray' }}>{this.props.subtitle}</p>}
				</Col>
				{this.props.items.length > this.props.rowItemCount && !this.props.hideControls && !this.props.allowHorizontalOverflow && <Col style={{ display: 'flex', marginBottom: '10px', justifyContent: 'flex-end', alignItems: 'center' }}>
							{this.getLeftButton()}
							{this.getRightButton()}
					</Col>}
			</Row>
			<div onMouseEnter={() => this.setState({ mouseOnDiv: true })}
				onMouseLeave={() => this.setState({ mouseOnDiv: false })}>
				<Row>
					{this.props.items.length > this.props.rowItemCount && !this.props.hideControls && this.props.allowHorizontalOverflow && <Col style={{ display: 'flex', marginBottom: '10px', justifyContent: 'flex-end', alignItems: 'center' }}>
							{this.getLeftButton()}
							{this.getRightButton()}
					</Col>}
				</Row>
				<Row style={this.getListStyle()}>
					{this.getInitialChunk().map((item, index) => (
						this.getRenderer(item, false, "300px", true)
					))}
				</Row>
			</div></>

		console.error(this.props.isMobile);

		return (
			<div style={{ marginBottom: 30 }}>
					{this.props.isMobile !== null && this.props.isMobile && mobileView}
					{this.props.isMobile !== null && !this.props.isMobile && defaultView}
					{this.props.isMobile === null && <>
						<Default>
							{defaultView}
						</Default>
						<Mobile>
							{mobileView}
						</Mobile>
					</>}
			</div>
		)
	}
}
