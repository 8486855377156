import React from 'react'
import Button from 'react-bootstrap/Button'
import ReactGA from 'react-ga'
import MetricsManager from '../managers/MetricsManager'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
export default class DownloadPlug extends React.Component { 
	constructor(props) { 
		super(props)
		this.metricsManager = new MetricsManager()
	}
	
	static defaultProps = { 
		includeText: true,
		id: "",
		title: "Always stay in the know",
		body: "Download the ETA app to have the best things in hip hop and black culture happening at your fingertips",
		style: { 
			margin: 20, 
			padding: 20
		}
	}

	componentDidMount() { 
		ReactGA.initialize('UA-139931598-1')
	}

	handleClick(source) { 
		var action = "download"
		
		if (this.props.id !== null) { 
			action += "-" + this.props.id.toString()
		}

		ReactGA.event({
			category: 'User',
			action: action,
			label: 'plug-' + source
		})

		this.metricsManager.logEvent("app_download", { type: source, identifier: this.props.id.toString()})

		var url = null
		if (source === "ios") { 
			url = "https://itunes.apple.com/us/app/gameplan-plug-into-nightlife/id1448003243?ls=1&mt=8"
		} else { 
			url = "https://play.google.com/store/apps/details?id=com.victoranyirah.Gameplan"
		}

		window.location.href = url
	}

	handleClickMetrics() { 
		this.metricsManager.logEvent("download_plug_click", { identifier: this.props.id.toString()})
	}

	getText() { 
		if (this.props.includeText) { 
			return (
				<React.Fragment>
					<h2>{this.props.title}</h2>
					<p>{this.props.body}</p>
				</React.Fragment>
				)
		}
		return null
	}

	render() { 
		if (this.props.simple) { 
			return <div onClick={() => {
				this.handleClickMetrics()
				window.location.href = "/downloads"
				}}style={{backgroundColor: 'black', padding: 10, color: 'white', textAlign: 'center'}}>
			<p style={{marginBottom: 0, fontSize: '0.85em'}}>ETA connects you to the best things happening for the culture. <a href="/downloads" style={{color: 'white', textDecoration: 'underline'}} onClick={this.handleClickMetrics.bind(this)}>Join 6k+ members by downloading the app for free</a></p>
		</div>
		}
		return (
			<Row>
				{this.props.includeImage && <Col xl style={{maxHeight: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
						<img src="/static/eta_mock_v1.png" height="400px"></img>
				</Col>}
				<Col style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
					<div style={{...{textAlign: 'center'}, ...this.props.style}}>
						{this.getText()}
						<img src="../static/apple_app.svg" height="40" style={{cursor: 'pointer'}} onClick={() => this.handleClick("ios")}/>
						<img src="../static/google-play-badge.png" height="60" style={{cursor: 'pointer'}}  onClick={() => this.handleClick("android")}/>
					</div>
				</Col>
			</Row>
			)

	}
}