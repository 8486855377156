import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { Default, Mobile } from './ViewTypes'
import ReactPlayer from 'react-player'
import Button from 'react-bootstrap/Button'
import { MdCancel } from 'react-icons/md'

export default class VideoPlayerModel extends React.Component { 

	constructor(props) { 
		super(props)
	}


	handleShow() { 
		return this.props.showStory
	}


	render() { 
		const useV2 = this.props.showStory && true

		if (useV2) { 
			return <div style={{width: '100%', position: 'absolute', height: '100%', backgroundColor: 'black', zIndex: 10000}} onBlur={() => this.props.onHide()}>
				<div style={{position: 'absolute', right: 10, top: 10, zIndex: 100000}}><Button size="lg" variant="light" onClick={() => this.props.handleHide()}><MdCancel size={34}></MdCancel></Button></div>
				<ReactPlayer url={this.props.url} playing width="100%" height="100%" controls onEnded={() => this.props.handleHide()}/>
			</div>
		}

		return <Modal size="lg"
			show={this.props.showStory} onHide={()=>this.props.handleHide()}
			style={{height:'100%', width: useV2 ? '100%' : null }}>
			  <Modal.Header closeButton>
		      </Modal.Header>
		      <Modal.Body>
		      <ReactPlayer url={this.props.url} playing width="100%"/>
      		  </Modal.Body>
       	 </Modal>
	}

}