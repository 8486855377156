import UtilsManager from "./UtilsManager";


const utilsManager = new UtilsManager();

export function canoc(title, id) { 
    return utilsManager.slugify(title + "-" + id);
}

export function getIdMatch(id) { 
    const regex = /(\d+$)/
	var matches = id.match(regex);

	if (matches !== null && matches !== undefined && matches.length !== 0) {
		return matches[0]
	}

    return null
}