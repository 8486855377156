import BasicCardItem from './BasicCardItem'


export default class PlaceCardCell extends React.Component { 
	
	render() { 
		return <BasicCardItem
							key={this.props.place.id}
							title={this.props.place.name}
							horizontal={this.props.horizontal}
							type={this.props.type}
							mobile={this.props.mobile}
							width={this.props.width}
							image_url={this.props.place.image}
							subtitleOne={this.props.place.address}
							subtitleTwo={this.props.place.hours}
							subtitleThree={null}
							onClick={() => this.props.onClick()}
						/>
	}
}