import React from 'react'
import LonelyCard from './LonelyCard'
import Card from 'react-bootstrap/Card'
import Constants from './Constants'

export default class BasicCardItem extends React.Component { 

	getCardStyle() { 
		const styles = {boxShadow: Constants.boxShadow,
						width: this.props.width,
						cursor: 'pointer'
					}
		if (this.props.horizontal) {
			styles.flex = '0 0 auto'
			styles.marginRight = "20px"
		}
		return styles
	}

		getImage() { 
		if (this.props.image_url == null || this.props.image_url == undefined) { 
			return "https://s3.us-east-2.amazonaws.com/gameplan-image-urls/icon.jpg"
		} else { 
			return this.props.image_url
		}
	}

	getImageStyle()  { 
		var height = "230px"
		if (this.props.mobile == true) { 
			height = "160px"
		}
		const divStyle = { 
			backgroundImage: `url(${this.getImage()})`, 
			width:'100%', 
			height:height,
			backgroundSize: 'cover',
			backgroundPosition: 'center', 
			diplay: 'flex'
		}

		return divStyle
	}

	getBody() { 
		return (
			<Card.Body>
			        <div onClick={()=> this.props.onClick()}>
				      <Card.Title><bold>{this.props.title}</bold></Card.Title>
				      <div style={{display:'flex', marginBottom:8}} onClick={()=> this.props.onClick()}>
					      <Card.Text>
					      {this.props.subtitleOne}
					      </Card.Text>
					   </div>
					 <div style={{display:'flex'}}>
					   <Card.Text>
					   	{this.props.subtitleTwo}
					   </Card.Text>
					 </div>

					 <div style={{marginTop: 20}}>
					 	<Card.Title>{this.props.subtitleThree}</Card.Title>
					 </div>
				 </div>
			    </Card.Body>
			)
	}


	render() {
		if (this.props.type === "lonely") { 
			return <LonelyCard style={{...this.getCardStyle(), ...this.props.style}} image={this.props.image_url}
				onClick={() => this.props.onClick()}>
			{this.getBody()}
			</LonelyCard>
		}
		return (
			<Card style={{...this.getCardStyle(), ...this.props.style}} className="event">
			    <div className="card-item" onClick={()=> this.props.onClick()} style={this.getImageStyle()}>
			    </div>
			    {this.getBody()}
			 </Card>
  		)

	}
}
